import "react-datepicker/dist/react-datepicker.css";
import "./TimeField.styles.scss";
import React, { ComponentProps, useState } from "react";
import DatePicker from "react-datepicker";

interface TimeFieldProps extends ComponentProps<"input"> {
  inputName: string | undefined;
  inputLabel?: string;
  errorMessage?: string;
  forwardRef?: any;
  hint?: string;
  inputType?: string;
}

const TimeField = ({
  inputName,
  inputLabel,
  errorMessage,
  forwardRef,
  hint,
  inputType = "time",
  ...props
}: TimeFieldProps) => {
  const [selectedTime, setSelectedTime] = useState(new Date());
  return (
    <div className="time-field">
      <label className="time-field__label">{inputLabel}</label>
      <DatePicker
        aria-label={inputLabel}
        className={`time-field__field ${errorMessage ? "error" : ""}`}
        ref={forwardRef}
        selected={selectedTime}
        onChange={(date: any) => setSelectedTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        name={inputName}
        type={inputType}
        {...props}
      />
      {errorMessage && (
        <p className="time-field__error">
          <small>{errorMessage}</small>
        </p>
      )}
      {!errorMessage && hint && (
        <p className="time-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default TimeField;
