/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./AssignmentCard.styles.scss";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AssignmentIcon } from "components";
import { Icon, Button } from "components";
import { useCurrentUser } from "utils/hooks";
import { type Assignment, type Section } from "types";
import getLastSubmission from "./utils/getLastSubmission";

interface AssignmentCardProps {
  assignment: Assignment | Section;
  submissions?: any;
  onCardClick?: () => void;
  onRemoveClick?: () => void;
  onScheduleClick?: () => void;
  onPreviewClick?: () => void;
  onConfigureClick?: () => void;
  isActive: boolean;
  showSubtitle: boolean;
  subtitle?: React.ReactNode;
  hasActions?: boolean;
  isExpired?: boolean;
}

const AssignmentCard = ({
  assignment,
  submissions,
  onCardClick,
  onRemoveClick,
  onScheduleClick,
  onPreviewClick,
  onConfigureClick,
  isActive,
  showSubtitle,
  subtitle,
  isExpired = false,
}: AssignmentCardProps) => {
  const { isTherapist } = useCurrentUser();

  const isAssignment = (obj: Assignment | Section): obj is Assignment => {
    return "actionPlanId" in obj;
  };
  const params = useParams();

  const navigate = useNavigate();

  const filteredSubmissions = (function () {
    if (submissions && submissions.length > 0) {
      return submissions.filter(
        (el: any) => el.assignmentLabel === assignment.label
      );
    } else {
      return [];
    }
  })();

  const getUrl = () =>
    `/patients/${params.uuid}/${params.slug}/${assignment.label
      .split(" ")
      .join("+")}`;

  return (
    <div
      className={`assignment-card ${
        isActive ? "assignment-card__active" : ""
      } ${isExpired ? "assignment-card__expired" : ""}`}
      onClick={onCardClick}
      id={assignment.slug}
    >
      <div className="assignment-card_title">
        {isAssignment(assignment) && assignment.actionPlanId && (
          <AssignmentIcon
            icon={assignment.icon?.src}
            style={assignment.type}
            color={assignment.color}
          />
        )}
        <div className="assignment-card_container">
          <div className="assignment-card_left">
            <div className="assignment-card_label">{assignment.label}</div>

            {isTherapist && submissions && filteredSubmissions.length !== 0 && (
              <div className="assignment-card_last-subm">
                {"Last Submission:  " + getLastSubmission(filteredSubmissions)}
              </div>
            )}

            {/* Button shows the submissions quantity for this assignment and leads to page with submissions table */}
            {isTherapist && filteredSubmissions.length !== 0 && (
              <Button
                value={filteredSubmissions.length + " Submissions"}
                name="assignment-card_submissions"
                extraClass="assignment-card_submissions"
                onClick={() => navigate(getUrl())}
              />
            )}
          </div>

          <div className="assignment-card_right">
            {showSubtitle && (
              <div className="assignment-card_subtitle">{subtitle}</div>
            )}

            {/* Buttons to work with this assignment  */}
            <div
              className="assignment-card_buttons"
              style={{ display: isTherapist ? "flex" : "none" }}
            >
              {filteredSubmissions.length === 0 && (
                <Button
                  value={
                    <>
                      <Icon src={"Remove"} />
                      <span>Remove</span>
                    </>
                  }
                  name="assignment-card_btn_remove"
                  extraClass="assignment-card_btn remove"
                  onClick={onRemoveClick}
                />
              )}
              <Button
                value={
                  <>
                    <Icon src={"Bell"} />
                    <span>Reminders</span>
                  </>
                }
                name="assignment-card_btn_reminders"
                extraClass="assignment-card_btn"
                onClick={onScheduleClick}
              />
              <Button
                value={
                  <>
                    <Icon src={"Configure"} />
                    <span>Configure</span>
                  </>
                }
                name="assignment-card_btn_configure"
                extraClass="assignment-card_btn"
                onClick={onConfigureClick}
              />
              <Button
                value={
                  <>
                    <Icon src={"Preview"} />
                    <span>Preview</span>
                  </>
                }
                name="assignment-card_btn_preview"
                extraClass="assignment-card_btn"
                onClick={onPreviewClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentCard;
